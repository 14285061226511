import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function markAsDirty(controls) {
  if (controls.controls) {
    controls.markAsDirty();
    controls.updateValueAndValidity();
    return markAsDirty(controls.controls);
  }
  for (const key in controls) {
    if (controls[key]) {
      if ((controls[key] instanceof FormGroup || controls[key] instanceof FormArray) && controls[key].controls) {
        markAsDirty(controls[key].controls);
      } else if (controls[key] instanceof FormControl) {
        controls[key].markAsDirty();
        controls[key].updateValueAndValidity();
      }
    }
  }
}

export function markAsPristine(controls, untouched = true) {
  if (controls.controls) {
    controls.markAsPristine();
    if (untouched) {
      controls.markAsUntouched();
    }
    controls.updateValueAndValidity();
    return markAsPristine(controls.controls);
  }
  for (const key in controls) {
    if (controls[key]) {
      if ((controls[key] instanceof FormGroup || controls[key] instanceof FormArray) && controls[key].controls) {
        markAsPristine(controls[key].controls);
      } else if (controls[key] instanceof FormControl) {
        controls[key].markAsPristine();
        if (untouched) {
          controls[key].markAsUntouched();
        }
        controls[key].updateValueAndValidity();
      }
    }
  }
}
