import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  HostListener,
  OnDestroy,
  OnInit, Output
} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "@space-web-core/store";
import {AuthActions} from "@space-web-core/store/auth/auth.actions";
import {Subscription} from "rxjs";
import {AuthService} from "@space-web-core/services/auth-service/auth.service";
import {User} from "@space-web-core/models/user";
import {environment} from "@space-web-env/environment";

@Component({
  selector: 'menu-navbar',
  templateUrl: './menu-navbar.component.html',
  styleUrls: ['./menu-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuNavbarComponent implements OnInit, OnDestroy {
  @Output() isMobileMenuActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  showBackDrop = false;
  showNavbar = false;
  user: User;
  private subscription = new Subscription();
  private _showMobileMenu = false;
  private _isMobileVieWidth = false;
  get showMobileMenu(): boolean {
    return this._showMobileMenu;
  }

  set showMobileMenu(show: boolean) {
    this._showMobileMenu = show;
    this.isMobileMenuActive.emit(show);
  }

  get isLogged(): boolean {
    return !!this.user?.email;
  }
  get isMobileVieWidth(): boolean {
    return this._isMobileVieWidth = window.innerWidth <= environment.mobileBreakPoint;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.isMobileVieWidth) {
      this.showMobileMenu = false;
      this.isMobileMenuActive.emit(false);
    }
  }

  @HostListener('window:message', ['$event']) onPostMessage(msg) {
    if(msg.data === 'modal') {
      this.showBackDrop = true;
    } else if (msg.data === 'closeModal') {
      this.showBackDrop = false
    }
  }

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.router.events.subscribe(r => {
        if (r instanceof NavigationEnd) {
          this.showNavbar =
            !r.url.includes('login') &&
            !r.url.includes('forgot-password') &&
            !r.url.includes('reset') &&
            !r.url.includes('register');
          this.cdr.detectChanges();
        }
      }),
    );

    this.subscription.add(
      this.authService.currentUser.subscribe(u => this.user = u)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeMobileMenuNabvar() {
    this.showMobileMenu = false;
  }

  logout() {
    localStorage.removeItem('redirectUrl');
    this.store.dispatch(AuthActions.logout());
  }
}
