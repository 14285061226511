import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { tap } from 'rxjs/operators';


/**
 * the service allows you to use HttpClient service with predefined modal messages
 * that will be displayed during the execution of the next http request
 */
@Injectable({
  providedIn: 'root',
})
export class HttpClientMessageService extends HttpClient {

  private loadingMessages: string[] = [];
  private errorMessages: string[] = [];
  private successMessages: string[] = [];

  private openedMessageIds: string[] = [];
  private openedMessagesIdMap: { [message: string]: string } = {};
  private nzDuration = 5000;

  readonly maxOpenedMessages = 3;

  constructor(
    handler: HttpHandler,
    private nzMessageService: NzMessageService,
  ) {
    super(handler);
  }

  duration(time: number) {
    this.nzDuration = time;
  }

  /**
   * define message that will be showed when request is in progress
   */
  loadingMessage(message: string): HttpClientMessageService {
    this.loadingMessages.push(message);
    return this;
  }

  /**
   * define message that will be showed after error
   */
  errorMessage(message: string): HttpClientMessageService {
    this.errorMessages.push(message);
    return this;
  }

  /**
   * define message that will be showed after success
   */
  successMessage(message: string): HttpClientMessageService {
    this.successMessages.push(message);
    return this;
  }

  /**
   * @override overloaded angular method, to support displaying messages
   */
  request(first: any, url?: string, options: any = {}): any {
    let loadingMessageId = null;
    if (this.loadingMessages.length) {
      loadingMessageId = this.showLoadingMessage(this.loadingMessages.shift());
    }

    let errorMessage = null;
    if (this.errorMessages.length) {
      errorMessage = this.errorMessages.shift();
    }

    let successMessage = null;
    if (this.successMessages.length) {
      successMessage = this.successMessages.shift();
    }

    return super.request(first, url, options).pipe(
      tap({
        next: () => {
          this.showSuccessMessage(successMessage);
          this.removeLoadingMessage(loadingMessageId);
        },
        error: () => {
          this.showErrorMessage(errorMessage);
          this.removeLoadingMessage(loadingMessageId);
        },
        complete: () => {
          this.removeLoadingMessage(loadingMessageId);
        },
      }),
    );
  }

  private showLoadingMessage(message: string) {
    // remove redundant messages
    if (this.openedMessageIds.length >= this.maxOpenedMessages) {
      this.removeLoadingMessage(this.openedMessageIds.shift());
    }
    // remove duplicates
    if (this.openedMessagesIdMap[message]) {
      this.removeLoadingMessage(this.openedMessagesIdMap[message]);
      const idx = this.openedMessageIds.findIndex(id => this.openedMessagesIdMap[message] === id)
      this.openedMessageIds.splice(idx, 1);
    }
    const nzMessageData = this.nzMessageService.loading(message, { nzDuration: 0 });
    const messageId = nzMessageData.messageId;
    this.openedMessageIds.push(messageId);
    this.openedMessagesIdMap[message] = messageId;
    return messageId;
  }

  private removeLoadingMessage(messageId: string) {
    if (messageId) {
      this.nzMessageService.remove(messageId);
    }
  }

  private showSuccessMessage(message: string) {
    if (message) {
      this.nzMessageService.success(message, { nzDuration: this.nzDuration });
    }
  }

  private showErrorMessage(message: string) {
    if (message) {
      this.nzMessageService.remove();
      this.nzMessageService.error(message, { nzDuration: this.nzDuration });
    }
  }

}
