import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {UserDetails} from "@space-web-core/models/user";
import {HttpClientMessageService} from "@space-web-core/services/http-client-message/http-client-message.service";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {AppState} from "@space-web-core/store";
import {HttpResponse} from "@angular/common/http";
import {environment} from "@space-web-env/environment";
import {AuthActions} from "@space-web-core/store/auth/auth.actions";
import {UserCache} from "@space-web-core/cache/user-cache";
import { catchError, map } from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: Observable<UserDetails>;
  private currentUserSubject: BehaviorSubject<UserDetails>;
  private readonly systemName = "space";
  get currentUserValue(): UserDetails {
    return this.currentUserSubject.value;
  }

  constructor(
    private httpClient: HttpClientMessageService,
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<UserDetails>(JSON.parse(localStorage.getItem('currentUser')));
    UserCache.setUser(this.currentUserSubject.value);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  validateResetToken(token: string) {
    return this.httpClient
      .post(`${ environment.cerberApi }/accounts/validate-reset-token`, { token, systemName: this.systemName })
      .pipe(
        catchError(error => throwError(error),
      ),
    );
  }

  register(email: string, password: string) {
    return this.httpClient.loadingMessage('Creating new account.')
      .errorMessage("Account hasn't been created.")
      .successMessage('Account has been created.')
      .post<UserDetails>(`${ environment.cerberApi }/accounts/register`, { email, password, systemName: this.systemName });
  }

  forgotPassword(email: string) {
    return this.httpClient
      .loadingMessage('Sending email')
      .errorMessage("Email hasn't been sent.")
      .post<string>(`${ environment.cerberApi }/accounts/forgot-password`, { email, systemName: this.systemName });
  }

  activateAccount(token: string): Observable<UserDetails> {
    return this.httpClient.loadingMessage('Activating account.')
      .errorMessage("Something went wrong.")
      .successMessage('Account has been activated.')
      .post<UserDetails>(`${ environment.cerberApi }/accounts/activate-authenticate`, { token },{ observe: 'response' })
      .pipe(
        map(r => {
          if(r.status === 200) {
            this.currentUserSubject.next(r.body);
            UserCache.setUser(r.body);
            localStorage.setItem('currentUser', JSON.stringify(r.body));
            sessionStorage.setItem('firstTimeAuth', '1');
            this.router.navigate(['dashboard']);
            return r.body;
          }
        }),
        catchError(error => {
          if(error.status === 422) {
            sessionStorage.setItem('isSecondAccountActivationAttempt', '1');
            this.router.navigate(['login']);
          }
          else {
            this.router.navigate(['register']);
          }
          return new Observable<UserDetails>();
        }),
      );
  }

  resetPassword(password: string, token: string): Observable<HttpResponse<string>> {
    return this.httpClient.loadingMessage('Changing password.')
      .errorMessage("Password hasn't been changed.")
      .successMessage('Password saved.')
      .post<string>(`${ environment.cerberApi }/accounts/reset-password`, { token: token, password: password, systemName: this.systemName }, { observe: 'response' });
  }

  login(email: string, password: string) {
    return this.httpClient
      .loadingMessage('Login in progress')
      .post<UserDetails>(`${environment.cerberApi}/accounts/authenticate`, { email, password, systemName: this.systemName })
      .pipe(
        map(user => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.store.dispatch(AuthActions.authorizeSuccess({ user }));
          UserCache.setUser(user);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.currentUserSubject.next(user);
          this.router.navigate(['/dashboard']);
          return user;
        }),
      );
  }

  loadUserEmail(token: string): Observable<UserDetails> {
    return this.httpClient.get<UserDetails>(`${environment.cerberApi}/accounts/email/${ token }`).pipe(
      map(user => {
        user.jwtToken = token;
        return user;
      })
    );
  }

  refreshToken(): Observable<UserDetails> {
    if(!UserCache.user || !UserCache.user?.refreshToken) {
      this.store.dispatch(AuthActions.logout());
    }

    return this.httpClient.post<UserDetails>(`${environment.cerberApi}/accounts/refresh-token`, {
      token: UserCache.user?.refreshToken || null,
    })
      .pipe(
        map(user => {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.store.dispatch(AuthActions.authorizeSuccess({ user }));
          this.store.dispatch(AuthActions.changeToken(user.jwtToken));
          UserCache.setUser(user);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.currentUserSubject.next(user);
          return user;
        }),
      );
  }

  resendVerificationEmail(email: string): Observable<HttpResponse<string>> {
    return this.httpClient.loadingMessage('Sending new verification email')
      .errorMessage("Verification email hasn't been sent.")
      .successMessage('Verification email has been sent.')
      .post<string>(`${ environment.cerberApi }/accounts/resend-verification-email`, { email, systemName: this.systemName }, { observe: 'response' });
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  checkEmailAlreadyRegistered(email: string): Observable<boolean> {
    return this.httpClient.get<HttpResponse<string>>(`${ environment.cerberApi }/Accounts/possible-register`, {params: {Email: email, systemName: this.systemName}})
      .pipe(
        map(() => false),
        catchError(() => of(true)),
        );
  }
}
