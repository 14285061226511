import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MenuNavbarComponent } from './components/menu-navbar/menu-navbar.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RegisterCarouselComponent } from '@space-web-core/components/register-carousel/register-carousel.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { MobileMenuComponent } from './components/menu-navbar/components/mobile-menu/mobile-menu.component';
import { NotAchieveResolutionComponent } from './components/not-achieve-resolution/not-achieve-resolution.component';
import { DatePeriodPipe } from './pipes/date-period.pipe';
import {Base64ImageSrcPipe} from "@space-web-core/pipes/base64-image-src.pipe";
import {AbstractAuthComponent} from "@space-web-core/components/auth/abstract-auth.component";

const components = [
  AbstractAuthComponent,
  MenuNavbarComponent,
  MobileMenuComponent,
  NotAchieveResolutionComponent,
  RegisterCarouselComponent,
];

const pipes = [DatePeriodPipe, Base64ImageSrcPipe];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    AngularSvgIconModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    NzCarouselModule,
  ],
  providers: [DatePipe],
  exports: [...components, ...pipes],
})
export class CoreModule {}
