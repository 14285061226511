<section>
  <div class="logo">
    <svg-icon src="/assets/images/large_logo.svg"></svg-icon>
  </div>

  <nz-carousel nzAutoPlay>
    <div nz-carousel-content>
      <div class="slide-img">
        <img src="assets/images/dashboard1.png" alt="dashboard 1" />
      </div>
      <span class="title-text">Real-time order status updates</span>
    </div>
    <div nz-carousel-content>
      <div class="slide-img">
        <img src="assets/images/dashboard2.png" alt="dashboard 2" />
      </div>
      <span class="title-text">Live van tracking</span>
    </div>
    <div nz-carousel-content>
      <div class="slide-img">
        <img src="assets/images/dashboard3.png" alt="dashboard 3" />
      </div>
      <span class="title-text">Access to your order history</span>
    </div>
  </nz-carousel>
</section>
