import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'base64ImageSrc'
})
export class Base64ImageSrcPipe implements PipeTransform {

  transform(base64: string): string {
    return 'data:image/png;base64,' + base64;
  }

}
