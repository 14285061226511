<section class="navbar-buttons">
  <div>
    <a class="btn nav-btn horizontal white-border"
      routerLink="/dashboard"
      routerLinkActive="active"
      (click)="close()"
    >
      <span>Dashboard</span>
      <svg-icon src="assets/images/icons/dashboard.svg"></svg-icon>
    </a>
    <a data-cy="ordersHeaderButton" class="btn nav-btn horizontal white-border"
       routerLink="/orders"
       routerLinkActive="active"
       (click)="close()"
    >
      <span>Orders</span>
      <svg-icon src="assets/images/icons/orders.svg"></svg-icon>
    </a>
    <a class="btn nav-btn white-border" *ngIf="!isLogged" routerLink="/login">
      Sign in
    </a>
    <a data-cy="mobileLogoutHeaderButton" class="btn nav-btn horizontal white-border" *ngIf="isLogged" (click)="doLogout()">
      Logout <svg-icon src="assets/images/icons/log-out-2.svg"></svg-icon>
    </a>
    <a class="btn nav-btn green" routerLink="/orders/new" (click)="close()">
      Get a new quote
    </a>
  </div>
</section>

