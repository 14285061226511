import {createAction, props} from "@ngrx/store";
import {ErrorPayload} from "@space-web-core/store/error";
import {UserDetails} from "@space-web-core/models/user";
import { HttpResponse } from "@angular/common/http";

export class AuthActions {
  static changeToken = createAction('[Auth] Token Change', (token: string) => ({ token }));

  static login = createAction('[Auth] Login', props<{ email: string; password: string; }>());
  static loginSuccess = createAction('[Auth] Login Success ✔️');
  static loginError = createAction('[Auth] Login Error ❌', props<{ error: HttpResponse<string>}>());

  static clearUserData = createAction('[Auth] Clear User Data ❌');

  static register = createAction('[Auth] Register', props<{ email: string; password: string; }>());
  static registerSuccess = createAction('[Auth] Register Success ✔️');
  static registerError = createAction('[Auth] Register Error ❌', props<ErrorPayload>());

  static forgotPassword = createAction('[Auth] Forgot Password', props<{ email: string; }>());
  static forgotPasswordSuccess = createAction('[Auth] Forgot Password Success ✔️');
  static forgotPasswordError = createAction('[Auth] Forgot Password Error ❌', props<ErrorPayload>());

  static resetPassword = createAction('[Auth] Reset Password', props<{ password: string; token: string; }>());
  static resetPasswordSuccess = createAction('[Auth] Reset Password Success ✔️');
  static resetPasswordError = createAction('[Auth] Reset Password Error ❌', props<ErrorPayload>());

  static activate = createAction('[Auth] Activate', props<{ activationToken: string }>());
  static activateSuccess = createAction('[Auth] Activate Success ✔️', props<UserDetails>());
  static activateError = createAction('[Auth] Activate Error ❌', props<ErrorPayload>());

  static resendActivationEmail = createAction('[Auth] Resend Activation Email', props<{ email: string }>());
  static resendActivationEmailSuccess = createAction('[Auth] Resend Activation Email ✔️');
  static resendActivationEmailError = createAction('[Auth] Resend Activation Email ❌', props<ErrorPayload>());

  static logout = createAction('[Auth] Logout 🔒');
  static logoutSuccess = createAction('[Auth] Logout  Success ✔');
  static logoutError = createAction('[Auth] Logout Error ❌', props<ErrorPayload>());

  static loadUserEmail = createAction('[User] Load User Email', props<{ token: string }>());
  static loadUserEmailSuccess = createAction('[User] Load User Email Success ✔️', props<{ user: UserDetails }>());
  static loadUserEmailError = createAction('[User] Load User Email Error ❌', props<ErrorPayload>());

  static refreshToken = createAction('[Auth] Refresh Token 🔑'); // authorize - receive new access token
  static refreshTokenSuccess = createAction('[Auth] Refresh Token Success ✔️');
  static refreshTokenError = createAction('[Auth] Refresh Token Error ❌', props<ErrorPayload>());

  static authorize = createAction('[Auth] Authorize 🔑'); // authorize - receive access token
  static authorizeSuccess = createAction('[Auth] Authorize Success ✔️', props<{ user: UserDetails }>());
  static authorizeError = createAction('[Auth] Authorize Error ❌', props<ErrorPayload>());

  static loadUser = createAction('[User] Load User');
  static loadUserSuccess = createAction('[User] Load User Success ✔️', props<{ user: UserDetails }>());
  static loadUserError = createAction('[User] Load User Error ❌', props<ErrorPayload>());

  static reconnectListeners = createAction('[Auth] Reconnect Listeners');
}
