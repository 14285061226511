import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import { ActionsSubject, Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { AppState } from "@space-web-core/store";
import {markAsDirty} from "@space-web-core/misc/mark-as-dirty";
import {Component, ElementRef, HostListener, OnDestroy, ViewChild} from "@angular/core";
import {Subject, Subscription} from "rxjs";
import {environment} from "@space-web-env/environment";

@Component({ template: '' })
export class AbstractAuthComponent implements OnDestroy {
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('confirmPasswordInput') confirmPasswordInput: ElementRef;
  form: FormGroup;
  readonly minPasswordLength = 8;
  protected subscription: Subscription = new Subscription();
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected _isMobileVieWidth = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this._isMobileVieWidth = this.isMobileVieWidth;
  }

  get isMobileVieWidth(): boolean {
    return this._isMobileVieWidth = window.innerWidth <= environment.mobileBreakPoint;
  }
  get formControls() {
    return this.form.controls;
  }

  get password() {
    return this.form.get('password');
  }

  get confirmPassword() {
    return this.form.get('password');
  }

  get isFormValid(): boolean {
    markAsDirty(this.form);
    return this.form.valid;
  }

  get isPasswordValid(): boolean {
    return this.form.get('password').valid;
  }

  get isConfirmPasswordValid(): boolean {
    return this.form.get('confirmPassword').valid;
  }

  constructor(
    protected actionsListener$: ActionsSubject,
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected store: Store<AppState>,
  ) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.complete();
  }

  showPassword(input: 'password' | 'confirmPassword') {
    if (input === 'password') {
      this.passwordInput.nativeElement.setAttribute('type', 'text')
    }
    if (input === 'confirmPassword') {
      this.confirmPasswordInput.nativeElement.setAttribute('type', 'text')
    }
  }

  hidePassword(type: 'password' | 'confirmPassword') {
    if (type === 'password') {
      this.passwordInput.nativeElement.setAttribute('type', 'password')
    }
    if (type === 'confirmPassword') {
      this.confirmPasswordInput.nativeElement.setAttribute('type', 'password')
    }
  }

  togglePassword(input: 'password' | 'confirmPassword') {
    let type = '';
    if (input === 'password') {
      type = this.passwordInput.nativeElement.getAttribute('type');
      type === 'text' ? this.hidePassword('password') : this.showPassword('password');
    }
    if (input === 'confirmPassword') {
      type = this.confirmPasswordInput.nativeElement.getAttribute('type');
      type === 'text' ? this.hidePassword('confirmPassword') : this.showPassword('confirmPassword');
    }
  }

  navigateToPrevious() {
    window.history.back();
  }

  updateConfirmValidator() {
    this.form.updateValueAndValidity();
  }

  protected confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    }

    if (control.value !== this.form.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  }
}
