import {User} from "@space-web-core/models/user";

export class UserCache {
  static user: User;

  static setUser(user: User) {
    this.user = user;
  }

  static removeUser() {
    delete this.user;
  }

  static get mailAddress(): string {
    return this.user.email;
  }
}
