import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "@space-web-core/store";
import {AuthActions} from "@space-web-core/store/auth/auth.actions";

@Component({
  selector: 'space-web-root',
  template: `
    <nz-layout>
      <menu-navbar></menu-navbar>
      <nz-content>
        <router-outlet></router-outlet>
      </nz-content>
    </nz-layout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private store: Store<AppState>,
  ) {
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }
}
