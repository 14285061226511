import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent {
  @Input() isLogged = false;
  @Output() logout: EventEmitter<boolean> = new EventEmitter();
  @Output() closeMobileMenu: EventEmitter<boolean> = new EventEmitter();

  doLogout() {
    this.logout.emit(true);
  }

  close() {
    this.closeMobileMenu.emit(true);
  }
}
