import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from '@ngrx/store';
import {AuthState} from "@space-web-core/store/auth/auth.state";
import {RouterStateUrl} from "@space-web-core/store/router/router.serializer";
import {AuthEffects} from "@space-web-core/store/auth/auth.effects";
import {AuthActions} from "@space-web-core/store/auth/auth.actions";
import {routerReducer, RouterReducerState} from "@ngrx/router-store";
import {authReducer} from "@space-web-core/store/auth/auth.reducer";

export interface AppState {
  auth: AuthState;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  router: routerReducer,
};

export const effects = [
  AuthEffects,
];

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if ( action && action.type === AuthActions.logout.type) {
      return reducer( undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [ logout ];

