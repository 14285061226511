import { Pipe, PipeTransform } from '@angular/core';
import {FromToDate} from "@space-web-core/models/date.model";
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'datePeriod',
})
export class DatePeriodPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(value: FromToDate): string {
    if(!value) return;

    const d1 = new Date(value.from);
    const d2 = new Date(value.to);

    if(d1.getDay() === d2.getDay() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
      if(d1.getHours() === d2.getHours()) {
        return `${this.datePipe.transform(value.from, 'dd.MM.YYYY HH:mm')}`;
      }
      return `${this.datePipe.transform(value.from, 'dd.MM.YYYY HH:mm')} - ${this.datePipe.transform(value.to, 'HH:mm')}`;
    } else {
      return `${this.datePipe.transform(value.from, 'dd.MM.YYYY')} - ${this.datePipe.transform(value.to, 'dd.MM.YYYY')} ${this.datePipe.transform(value.from, 'HH:mm')}-${this.datePipe.transform(value.to, 'HH:mm')}`;
    }
  }
}
