import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CoreModule } from "./core/core.module";
import {AngularSvgIconModule} from "angular-svg-icon";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {EffectsModule} from "@ngrx/effects";
import {effects, metaReducers, reducers} from "@space-web-core/store";
import {StoreModule} from "@ngrx/store";
import {StoreRouterConnectingModule} from "@ngrx/router-store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {RouterBasicSerializer} from "@space-web-core/store/router/router.serializer";
import { en_GB, NZ_I18N } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import en from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./interceptors/token.interceptor";

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    NzLayoutModule,
    NzMessageModule,
    EffectsModule.forRoot(effects),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      //logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({ serializer: RouterBasicSerializer }),
  ],
  providers:[
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: NZ_I18N, useValue: en_GB },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
