import {UserDetails} from "@space-web-core/models/user";

export interface AuthState {
  authorized: boolean;
  jwtToken: string;
  user?: UserDetails;
}

export const initialAuth: AuthState = {
  authorized: false,
  jwtToken: null,
  user: null,
};
