import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import {AuthService} from "@space-web-core/services/auth-service/auth.service";
import {AuthActions} from "@space-web-core/store/auth/auth.actions";
import {UserCache} from "@space-web-core/cache/user-cache";
import { environment } from '@space-web-env/environment';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private nzMessageService: NzMessageService,
  ) {
  }

  activate$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.activate),
    exhaustMap(({ activationToken }) => this.authService.activateAccount(activationToken).pipe(
      switchMap((user) => [
        AuthActions.activateSuccess(user),
      ]),
    )),
  ));

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    exhaustMap(({ email, password }) => this.authService.login(email, password).pipe(
        switchMap((user) => [
          AuthActions.loginSuccess(),
          AuthActions.authorizeSuccess({ user }),
        ]),
        catchError(error => of(AuthActions.loginError({ error }))),
      ),
    ),
  ));

  forgotPassword$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.forgotPassword),
    exhaustMap(({ email }) => this.authService.forgotPassword(email).pipe(
        switchMap(() => [
          AuthActions.forgotPasswordSuccess(),
        ]),
        catchError(error => of(AuthActions.forgotPasswordError({ error }))),
      ),
    ),
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.resetPassword),
    exhaustMap(({ password, token }) => this.authService.resetPassword(password, token).pipe(
        switchMap(() => [
          AuthActions.resetPasswordSuccess(),
        ]),
        catchError(error => of(AuthActions.resetPasswordError({ error }))),
      ),
    ),
  ));

  register$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.register),
    exhaustMap(({ email, password }) => this.authService.register(email, password).pipe(
        switchMap(() => {
          sessionStorage.setItem('registeredEmail', email);
          return [AuthActions.registerSuccess()];
        }),
        catchError(error => of(AuthActions.registerError({ error }))),
      ),
    ),
  ));

  resendActivationEmail$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.resendActivationEmail),
    exhaustMap(({ email }) => this.authService.resendVerificationEmail(email).pipe(
        switchMap(() => {
          return [AuthActions.resendActivationEmailSuccess()];
        }),
        catchError(error => of(AuthActions.resendActivationEmailError({ error }))),
      ),
    ),
  ));

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.refreshToken),
    exhaustMap(() => this.authService.refreshToken().pipe(
      switchMap((user) => [
        AuthActions.loadUserEmailSuccess({ user }),
      ]),
      catchError(error => of(AuthActions.refreshTokenError({ error }))),
      ),
    ),
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess),
    tap(() => {
      this.router.navigateByUrl(`/dashboard`);
    })
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map(() => {
      this.authService.logout();
      this.resetCache();
      this.redirectToQGLandingPage();
      return AuthActions.logoutSuccess();
    }),
    catchError(error => of(AuthActions.logoutError({ error, message: 'Błąd podczas wylogowania' }))),
  ));

  changeToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.changeToken),
    tap(({ token }) => localStorage.setItem('token', token)),
  ), { dispatch: false });

  // handle errors
  error$ = createEffect(() => this.actions$.pipe(
    ofType(
      // AuthActions.loginError,
      AuthActions.authorizeError,
      AuthActions.loadUserEmailError,
      AuthActions.logoutError,
    ),
    switchMap(({ error, message }) => {
      if (message) {
        this.nzMessageService.error(message, { nzDuration: 4000 });
      }
      console.error(error);
      return this.redirectToLoginPage();
    }),
  ), { dispatch: false });


  clear$ = createEffect(() => this.actions$.pipe(
    ofType(
      AuthActions.clearUserData,
      AuthActions.loginError,
      AuthActions.authorizeError,
      AuthActions.logoutError,
      AuthActions.refreshTokenError,
    ),
    tap(() => {
      this.resetCache();
      this.redirectToLoginPage();
    }),
  ), { dispatch: false });

  loadUserEmail$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loadUserEmail),
    exhaustMap(({ token }) => this.authService.loadUserEmail(token).pipe(
      switchMap((user) => [
        AuthActions.loadUserEmailSuccess({ user }),
      ]),
      catchError(error => of(AuthActions.loadUserEmailError({ error }))),
    )),
  ));

  private resetCache() {
    UserCache.removeUser();
    const config = localStorage.getItem('appConfig'); // This should not be removed ;)
    localStorage.clear();
    localStorage.setItem('appConfig', config);
    localStorage.removeItem('currentUser');
  }

  private redirectToLoginPage(): Observable<boolean> {
    return from(this.router.navigate(['/login']));
  }

  private redirectToQGLandingPage() {
    window.location.href = environment.quoteAndGoUrl;
  }

  private redirectToStartPage(): Observable<boolean> {
    return from(this.router.navigate(['/']));
  }
}
