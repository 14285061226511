import { Action, createReducer, on } from '@ngrx/store';
import {AuthState, initialAuth} from "@space-web-core/store/auth/auth.state";
import {AuthActions} from "@space-web-core/store/auth/auth.actions";

const clearAuth = (state, action) => initialAuth;

const reducer = createReducer(
  initialAuth,
  on(AuthActions.authorizeSuccess, (state, { user }) => ({ ...state, user: user, authorized: true })),
  on(AuthActions.changeToken, (state, { token }) => ({ ...state, token  })),
  on(AuthActions.loadUserEmailSuccess, (state, { user }) => ({ ...state, user: user  })),
  on(AuthActions.loginError, clearAuth),
  on(AuthActions.loadUserEmailError, clearAuth),
  on(AuthActions.logout, clearAuth),
  on(AuthActions.activateSuccess, (state, user) => {
    return {
      ...state,
      user: user,
      jwtToken: user.jwtToken,
      authorized: true,
    }
  })
);

export function authReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}
