import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, take, throwError } from 'rxjs';
import { AuthService } from "@space-web-core/services/auth-service/auth.service";
import { catchError, filter, switchMap, tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshingAccessToken = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('authenticate')) next.handle(request);

    const jwtToken = this.authService.currentUserValue?.jwtToken;
    if(jwtToken) { //user has logged in account
      const requestWithJwt = this.addToken(request, jwtToken);
      return next.handle(requestWithJwt).pipe(
        catchError(error => {
          if (this.isUnauthorizedUserError(error)) {
            return this.handleTokenExpiredError(request, next);
          } else {
            return throwError(error);
          }
        }));
    }
    //user not logged in or browsing without account
    return next.handle(request).pipe(
      catchError(
        error => {
          if (this.isUnauthorizedUserError(error)) {
            return this.redirectToLoginPage(request, next);
          } else {
            return throwError(error);
          }
         }));
  }

  private redirectToLoginPage(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.router.navigateByUrl('login');
    return next.handle(request).pipe(
      tap({
        error: () => {
          this.router.navigate(['/login'])
          return throwError(() => new Error('User not logged in or user cache corrupted'));
        }
      }),
    );
  }

  private addToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: { 'Authorization': `${token}` },
    });
  }

  private isUnauthorizedUserError(error: HttpErrorResponse): boolean {
    return error instanceof HttpErrorResponse && error.status === 401;
  }

  private handleTokenExpiredError(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.isRefreshingAccessToken) {
      return this.refreshTokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
            return next.handle(this.addToken(request, jwt));
          }));
    }
    else {
      this.isRefreshingAccessToken = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((user) => {
        this.isRefreshingAccessToken = false;
        this.refreshTokenSubject.next(user.jwtToken);
        return next.handle(this.addToken(request, user.jwtToken));
      }),
        catchError(() => this.redirectToLoginPage(request, next)),
      );
    }
  }
}
